import React from "react";
import { useParams } from "react-router-dom";
import LeaderboardApp from ".";

export const LeaderboardUrlWrapper = () => {
	// Extract the 'botid' parameter from the URL
	const { mall } = useParams();

	return <LeaderboardApp mall={mall} />;
};
