import React, { useState } from "react";
import {
	View,
	Text,
	StyleSheet,
	TouchableOpacity,
	CheckBox,
} from "react-native";

export const PopiaCheckboxes = () => {
	const [formData, setFormData] = useState({
		brandCommunication: false,
		optIn: false,
	});

	const handleChange = (field) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	return (
		<View style={styles.container}>
			<TouchableOpacity
				onPress={() => handleChange("brandCommunication")}>
				<View style={styles.checkboxContainer}>
					<View
						style={{
							width: 19,
							height: 19,
							borderRadius: 5,
							backgroundColor: formData.brandCommunication
								? "#11C0E1"
								: "transparent",
							borderWidth: 1,
							borderColor: formData.brandCommunication
								? "#11C0E1"
								: "#000",
						}}
					/>
					<Text style={styles.label}>
						I consent to receiving brand communication from
						Elizabeth Arden.
					</Text>
				</View>
			</TouchableOpacity>

			<TouchableOpacity onPress={() => handleChange("optIn")}>
				<View style={styles.checkboxContainer}>
					{/* <CheckBox
					value={formData.optIn}
					onValueChange={() => handleChange("optIn")}
				/> */}
					<View
						style={{
							width: 19,
							height: 19,
							borderRadius: 5,
							backgroundColor: formData.optIn
								? "#11C0E1"
								: "transparent",
							borderWidth: 1,
							borderColor: formData.optIn ? "#11C0E1" : "#000",
						}}
					/>
					<Text style={styles.label}>
						I opt in to receive marketing and promotional
						information from Elizabeth Arden.
					</Text>
				</View>
			</TouchableOpacity>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// alignItems: "center",
		// justifyContent: "center",
		marginBottom: 20,
		marginTop: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 20,
	},
	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 10,
	},
	label: {
		marginLeft: 8,
		fontSize: 16,
	},
	button: {
		backgroundColor: "blue",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		marginTop: 20,
	},
	buttonText: {
		color: "white",
		fontWeight: "bold",
		fontSize: 16,
	},
});
