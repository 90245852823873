import React, { Component } from "react";
import { View, Text } from "react-native";
import { TouchableOpacity } from "react-native-web";

export default class Instruction extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		return (
			<View
				style={{
					// flex: 1,
					justifyContent: "center",
					alignItems: "center",
					width: window.innerWidth,
					height: window.innerHeight,
					marginLeft: "auto",
					marginRight: "auto",

					backgroundColor: "#F8F8F8",
					padding: 10,
				}}>
				<div className='bg-image'></div>
				<View
					style={{
						width: "100%",
						justifyContent: "center",
						// alignItems: "center",
						// marginBottom: 10,
						// flex: 1,
						zIndex: 99,
						// padding: 20,
						maxWidth: 400,
						paddingLeft: 20,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 22,
							color: "#000",
							// textAlign: "center",
						}}>
						<strong>Complete the quiz & win!</strong>
					</Text>
				</View>
				<View
					style={{
						width: "100%",
						justifyContent: "center",
						// alignItems: "center",
						marginBottom: 10,
						// flex: 1,
						zIndex: 99,
						padding: 20,
						maxWidth: 400,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "600",
							fontSize: 25,
							color: "#000",
							// marginTop: 20,
							marginBottom: 5,
						}}>
						Step 1:
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						Press the <strong>launch</strong> button and point your
						camera at the new
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						<strong>Eight Hour® HydraPlay </strong> product.
					</Text>
				</View>
				<View
					style={{
						width: "100%",
						justifyContent: "center",
						// alignItems: "center",
						marginBottom: 10,
						// flex: 1,
						zIndex: 99,
						padding: 20,
						maxWidth: 400,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "600",
							fontSize: 25,
							color: "#000",
							// marginTop: 20,
							marginBottom: 5,
						}}>
						Step 2:
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						Take note of the <strong>8</strong> key benefits.
					</Text>
				</View>
				<View
					style={{
						width: "100%",
						justifyContent: "center",
						// alignItems: "center",
						marginBottom: 10,
						// flex: 1,
						zIndex: 99,
						padding: 20,
						maxWidth: 400,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "600",
							fontSize: 25,
							color: "#000",
							// marginTop: 20,
							marginBottom: 5,
						}}>
						Step 3:
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						Press the hashtag
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						<strong>#HydraPlayYourWay</strong>
					</Text>
				</View>
				<View
					style={{
						width: "100%",
						justifyContent: "center",
						// alignItems: "center",
						marginBottom: 10,
						// flex: 1,
						zIndex: 99,
						padding: 20,
						maxWidth: 400,
					}}>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "600",
							fontSize: 25,
							color: "#000",
							// marginTop: 20,
							marginBottom: 5,
						}}>
						Step 4:
					</Text>
					<Text
						style={{
							fontFamily: "Gotham",
							fontWeight: "400",
							fontSize: 19,
							color: "#000",
							// textAlign: "center",
						}}>
						Complete the quiz and stand a chance to{" "}
						<strong>WIN!</strong>
					</Text>
				</View>
				<TouchableOpacity
					style={{
						width: "100%",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "blue",
						maxWidth: 400,
						borderRadius: 10,
						zIndex: 99,
						padding: 10,
					}}
					onPress={() => {
						window.location =
							"https://mywebar.com/p/8HourHydraPlay";
					}}>
					<View>
						<Text
							style={{
								fontFamily: "Gotham",
								fontWeight: "600",
								fontSize: 16,
								color: "#FFF",
								// marginTop: 20,
								// marginBottom: 15,
							}}>
							Begin
						</Text>
					</View>
				</TouchableOpacity>
			</View>
		);
	}
}
