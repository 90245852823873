import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ScrollView,
} from "react-native";
// import { api_qrscanned } from "./Api";
import Fade from "react-reveal/Fade";

const handleInstagramClick = () => {
	// window.location.href = "https://www.instagram.com/elizabetharden_sa/";
	const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
	window.open("https://www.instagram.com/elizabetharden_sa", "_blank");

	fetch(
		"https://workflow.tetrice.co.za/webhook/638e93bf-a1f0-4011-ab42-9fede84550ae",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				allocation: "EA - Hydraplay - Instagram follow",
				...analyticsData,
			}),
		},
	)
		.then((response) => response.json())
		.then((data) => {
			// window.open(
			// 	"https://www.instagram.com/elizabetharden_sa",
			// 	"_blank",
			// );
		})
		.catch((error) => {
			console.error(error);
			this.setState({ isLoading: false });
		});
};

const handleFacebookClick = () => {
	const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
	// Do something with the form data
	window.open(
		"https://web.facebook.com/elizabethardensouthafrica/?_rdc=1&_rdr",
	);

	fetch(
		"https://workflow.tetrice.co.za/webhook/638e93bf-a1f0-4011-ab42-9fede84550ae",
		{
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				allocation: "EA - Hydraplay - Facebook like",
				...analyticsData,
			}),
		},
	)
		.then((response) => response.json())
		.then((data) => {})
		.catch((error) => {
			console.error(error);
			this.setState({ isLoading: false });
		});
};

export default class ThankYou extends Component {
	constructor(props) {
		super(props);
		this.state = {
			bottomImages: [
				{
					uri: require("../Quiz/hp3.jpg"),
				},
				{
					uri: require("../Quiz/hp2.jpg"),
				},
			],
		};
	}

	render() {
		let height = window.innerHeight * 0.5;
		let width = window.innerWidth;

		width = width > 600 ? 600 : width;

		return (
			<View
				style={{
					// flex: 1,
					justifyContent: "center",
					alignItems: "center",
					width: width,
					height: window.innerHeight,
					marginLeft: "auto",
					marginRight: "auto",

					// backgroundColor: "#F8F8F8",
				}}>
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							justifyContent: "center",
							alignItems: "center",
						}}>
						<View
							style={{
								// position: "absolute",
								alignItems: "center",
								width: width,
								zIndex: 99,
								// top: "30%",
								// backgroundColor: "red",
								top: -50,
							}}>
							<img
								src={require("../Quiz/hp1.jpg")}
								alt='Description'
								className='responsive'
							/>
						</View>

						{/* <View
							style={{
								width: "50%",
								marginBottom: 50,
								maxWidth: 250,
								marginTop: 15,
							}}>
							<img
								src='https://www.elizabetharden.co.za/images/sitewide/EA-Logo-2020-320x59.svg'
								alt='Edgars logo'
								className='responsive'
							/>
						</View> */}

						<View
							style={{
								justifyContent: "center",
								alignItems: "center",
								padding: 20,
							}}>
							<Text
								style={{
									fontSize: 25,
									fontWeight: "800",
									color: "#000",
								}}>
								Thank you for entering!
							</Text>

							{/* <Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "400",
									fontSize: 16,
									marginVertical: 10,
								}}>
								Come JOIN us at Comic-Con from the
							</Text> */}
							{/* <Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "400",
									fontSize: 16,
								}}>
								22-25th of Spetember '23
							</Text> */}
							<Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "400",
									fontSize: 14,
									marginTop: 20,
								}}>
								Like & Follow us on our social pages
							</Text>
							<TouchableOpacity
								// style={styles.input}
								onPress={() => handleInstagramClick()}>
								<Image
									source={require("../assets/instagram.png")}
									style={{
										width: window.innerWidth * 0.8,
										height: 50,
										resizeMode: "contain",
										marginVertical: 15,
									}}
								/>
							</TouchableOpacity>

							<TouchableOpacity
								// style={styles.input}
								onPress={() => handleFacebookClick()}>
								<Image
									source={require("../assets/facebook.png")}
									style={{
										width: window.innerWidth * 0.8,
										height: 50,
										resizeMode: "contain",
										marginVertical: 15,
									}}
								/>
							</TouchableOpacity>
							<Text
								style={{
									opacity: 0.7,
									fontSize: 16,
								}}>
								or
							</Text>
							<Text
								style={{
									// opacity: 0.7,
									marginTop: 18,
									fontFamily: "Gotham",
									fontWeight: "600",
									fontSize: 16,
								}}>
								Sign up to Join our community
							</Text>
							<View
								style={{
									height: 10,
								}}
							/>

							<TouchableOpacity
								style={{
									width: "100%",
									maxWidth: 300,
									// height: 40,
									// borderRadius: 12,
									// backgroundColor: "#E584A7",
									// alignItems: "center",
									// justifyContent: "center",
									marginTop: 16,
									marginBottom: 80,
								}}
								onPress={() =>
									(window.location.href = "/signupform")
								}>
								<Fade>
									<img
										src={require("../assets/sign-up.png")}
										alt='Description'
										className='responsive'
									/>
								</Fade>
							</TouchableOpacity>
						</View>
						{this.state.bottomImages.map((d, i) => {
							return (
								<View
									style={{
										width: "100%",
										alignItems: "center",
										justifyContent: "center",
									}}>
									<img
										// src={{
										// 	uri: d.uri,
										// }}
										src={d.uri}
										alt=''
										className='responsive'
									/>
								</View>
							);
						})}
					</View>
				</ScrollView>
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		alignItems: "center",
		// justifyContent: "center",
		// backgroundColor: "#FFF0E8",
		paddingHorizontal: 16,
		height: window.innerHeight,
	},
	loadingContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FFF",
		paddingHorizontal: 16,
		minHeight: window.innerHeight,
	},
	title: {
		fontSize: 20,
		fontWeight: "300",

		// marginBottom: 16,
		// marginTop: 25,
		color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: 48,
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
		backgroundColor: "#FFF",
	},
	row: {
		width: "100%",
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
		marginTop: 10,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		fontSize: 15,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#D44343",
	},

	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#D44343",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});
