import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { api_enterCompetition } from "../Api";
import EmailInput from "../EmailInput";
import { PopiaCheckboxes } from "./POPIA";
import axios from "axios";
import ReceiptUploader from "./ReceiptUploader";
import SouthAfricanCellNumberInput from "../SouthAfricanCellNumberInput";
import AppTermsAndConditions from "./AppTermsAndConditions";
import { TermsAndConditionsCheckBox } from "./TermsAndConditions";

function generateFormId() {
	let formId = "";
	const characters = "abcdef0123456789";
	for (let i = 0; i < 16; i++) {
		const randomIndex = Math.floor(Math.random() * characters.length);
		const randomCharacter = characters[randomIndex];
		if (i === 4 || i === 8 || i === 12) {
			formId += "-";
		}
		formId += randomCharacter;
	}
	return formId;
}

const Comp = ({ navigation }) => {
	const [isLoading, setIsLoading] = useState(false);

	const [isCached, seisCached] = useState(false);
	const [signupData, setsignupData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		cell: "",
		preferredMethod: "",
		base64: "",
		acceptedTCs: "",
	});

	useEffect(() => {
		const storedSignupData = localStorage.getItem("signupData");
		if (storedSignupData) {
			setsignupData(JSON.parse(storedSignupData));
			seisCached(true);
		}
	}, []);

	const geoAppify1 = async () => {
		try {
			const { data } = await axios.get(`https://ipapi.co/json/`);

			const analyticsData = {
				...data,
				userAgent: window.navigator.userAgent,
				screenWidth: window.screen.width,
				screenHeight: window.screen.height,
			};

			return analyticsData;
		} catch (error) {
			console.error(error);
			return null;
		}
	};

	const handleChange = (name, value) => {
		setsignupData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		try {
			setIsLoading(true);
			let analyticsData = JSON.parse(
				localStorage.getItem("analyticsData"),
			);

			const ip = await geoAppify1();

			if (!analyticsData) {
				console.log("geoAppify SET", ip);
				let form_id = generateFormId();
				analyticsData = {
					...ip,
					userAgent: window.navigator.userAgent,
					screenWidth: window.screen.width,
					screenHeight: window.screen.height,
					form_id: form_id,
				};
				localStorage.setItem(
					"analyticsData",
					JSON.stringify(analyticsData),
				);
			}

			// Do something with the form data
			console.log(signupData);
			if (signupData.email || signupData.cell) {
				await fetch(api_enterCompetition, {
					method: "POST",
					headers: {
						"Content-Type": "application/json",
					},
					body: JSON.stringify({
						form_id: analyticsData.form_id,
						competition: "8Hour_Comiccon",
						...signupData,
					}),
				})
					.then((response) => response.json())
					.then((data) => {
						console.log(data);

						// localStorage.setItem(
						// 	"signupData",
						// 	JSON.stringify(signupData),
						// );
						// // navigation.navigate("Quiz");
						window.location.href = "/thankyou";
						// //
					})
					.catch((error) => {
						console.error(error);
						this.setState({ isLoading: false });
					});
			}
		} catch (error) {
			console.log(error);
		}
	};

	const originalWidth = 2400;
	const originalHeight = 2400;

	// Calculate the height based on the aspect ratio
	// const height = (window.innerWidth * originalHeight) / originalWidth;

	const originalWidth2 = 2400;
	const originalHeight2 = 2400;

	// Calculate the height based on the aspect ratio
	const height2 = (window.innerWidth * originalHeight2) / originalWidth2;

	let height = window.innerHeight * 0.5;
	let width = window.innerWidth;

	width = width > 600 ? 600 : width;

	return (
		<View style={styles.container}>
			{isLoading ? (
				<View style={styles.loadingContainer}>
					<Image
						source={require("../assets/ealogo.png")}
						style={{
							width: window.innerWidth / 2,
							height: height2 / 2,
							resizeMode: "contain",
							maxWidth: 175,
							maxHeight: 200,
						}}
					/>

					<ActivityIndicator size='large' color='#11C0E1' />
				</View>
			) : (
				<ScrollView showsVerticalScrollIndicator={false}>
					<View
						style={{
							flex: 1,
							minHeight: window.innerHeight,
							width: "100%",
							// maxWidth: 550,
						}}>
						<View
							style={{
								// position: "absolute",
								alignItems: "center",
								width: width,
								zIndex: 99,
								// top: "30%",
								// backgroundColor: "red",
							}}>
							<img
								src={require("../assets/TopSplash.webp")}
								alt='Description'
								className='responsive'
							/>
						</View>

						{/* <View
							style={{
								width: "50%",
								marginBottom: 20,
								maxWidth: 250,
								marginTop: 15,
								paddingHorizontal: 16,
							}}>
							<img
								src='https://www.elizabetharden.co.za/images/sitewide/EA-Logo-2020-320x59.svg'
								alt='Edgars logo'
								className='responsive'
							/>
						</View> */}

						<View
							style={{
								alignItems: "center",
								width: "100%",
								marginBottom: 25,
							}}>
							{/* <Image
							source={require("../assets/win22.png")}
							style={{
								width: window.innerWidth,
								height: height2,
								resizeMode: "contain",
								maxWidth: 350,
								maxHeight: 400,
							}}
						/> */}
						</View>

						<View
							style={{
								paddingHorizontal: 16,
								maxWidth: 450,
								marginLeft: "auto",
								marginRight: "auto",
							}}>
							<ReceiptUploader
								handleUpload={(v) => handleChange("base64", v)}
							/>
							{/* {isCached ? null : ( */}
							<View
								style={{
									width: "100%",
									display:
										signupData.base64 !== ""
											? "flex"
											: "none",
								}}>
								<TextInput
									autoCapitalize='words'
									style={styles.input}
									placeholder='First Name'
									value={signupData.firstName}
									onChangeText={(value) =>
										handleChange("firstName", value)
									}
								/>
								<TextInput
									autoCapitalize='words'
									style={styles.input}
									placeholder='Last Name'
									value={signupData.lastName}
									onChangeText={(value) =>
										handleChange("lastName", value)
									}
								/>

								<View style={styles.row}>
									<Text style={styles.label}>
										Preferred method of contact:
									</Text>
									<View style={styles.buttonContainer}>
										<TouchableOpacity
											style={[
												{
													flex: 1,
													// width: "80%",
													height: 48,
													borderRadius: 12,
													backgroundColor: "#B1ACAA",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 16,
												},
												signupData.preferredMethod ===
													"email" &&
													styles.activeButton,
											]}
											onPress={() =>
												handleChange(
													"preferredMethod",
													"email",
												)
											}>
											<Text style={styles.buttonText}>
												Email
											</Text>
										</TouchableOpacity>
										<View
											style={{
												width: 10,
											}}
										/>
										<TouchableOpacity
											style={[
												{
													flex: 1,
													height: 48,
													borderRadius: 12,
													backgroundColor: "#B1ACAA",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 16,
												},
												signupData.preferredMethod ===
													"cell" &&
													styles.activeButton,
											]}
											onPress={() =>
												handleChange(
													"preferredMethod",
													"cell",
												)
											}>
											<Text style={styles.buttonText}>
												Cell
											</Text>
										</TouchableOpacity>
									</View>
								</View>
							</View>
							{/* )} */}

							{signupData.base64 !== "" ? (
								<View
									style={{
										width: "100%",
										marginBottom: 200,
										alignItems: "center",
										justifyContent: "center",
									}}>
									<PopiaCheckboxes />

									<View style={styles.row}>
										<Text style={styles.label}>
											Please provide a valid contact
										</Text>
									</View>

									{signupData.preferredMethod === "email" ? (
										<EmailInput
											onChange={(val) =>
												handleChange("email", val)
											}
										/>
									) : null}
									{signupData.preferredMethod === "cell" ? (
										<SouthAfricanCellNumberInput
											onChange={(val) =>
												handleChange("cell", val)
											}
											defaultValue={signupData.cell}
										/>
									) : null}

									<TermsAndConditionsCheckBox
										isCheckBoxType={true}
										isAccepted={() => {
											handleChange("acceptedTCs", "true");
										}}
									/>

									{typeof signupData.base64 !== "undefined" &&
									signupData.base64 !== "" &&
									signupData.preferredMethod !== "" &&
									signupData.acceptedTCs !== "" &&
									(signupData.cell !== "" ||
										signupData.email !== "") ? (
										<TouchableOpacity
											disabled={
												signupData.preferredMethod ===
												"email"
													? signupData.email === ""
													: signupData.cell === ""
											}
											style={{
												// width: "100%",
												// height: 48,
												// borderRadius: 12,
												// backgroundColor: "#11C0E1",
												alignItems: "center",
												justifyContent: "center",
												// marginTop: 16,
												maxWidth: 300,
											}}
											onPress={handleSubmit}>
											{/* <Text style={styles.buttonText}>
												Submit
											</Text> */}
											<img
												src={require("../assets/enter_button.png")}
												alt='Description'
												className='responsive'
											/>
										</TouchableOpacity>
									) : null}
								</View>
							) : null}
							{/* {console.log(signupData.base64)} */}
						</View>
					</View>
				</ScrollView>
			)}
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		// backgroundColor: "#FFF0E8",
		// paddingHorizontal: 16,
		width: window.innerWidth,
		height: window.innerHeight,
	},
	title: {
		fontSize: 20,
		fontWeight: "300",

		// marginBottom: 16,
		// marginTop: 25,
		color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: 48,
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
		backgroundColor: "#FFF",
	},
	row: {
		width: "100%",
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
		marginTop: 10,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		fontSize: 15,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#11C0E1",
	},

	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#11C0E1",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
		fontWeight: "500",
	},
	loadingContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#fff",
		minHeight: window.innerHeight,
		padding: 30,
	},
});

export default Comp;
