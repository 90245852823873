import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
} from "react-native";
// import { api_qrscanned } from "../Api";
// import EmailInput from "../EmailInput";
// import Fade from "react-reveal/Fade";
// import SouthAfricanCellNumberInput from "../SouthAfricanCellNumberInput";

const FollowUs = ({ navigation, root }) => {
	const [signupData, setsignupData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		cell: "",
		preferredMethod: "",
	});

	const handleInstagramClick = () => {
		// window.location.href = "https://www.instagram.com/elizabetharden_sa/";
		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		window.open("https://www.instagram.com/elizabetharden_sa", "_blank");

		fetch(
			"https://workflow.tetrice.co.za/webhook/638e93bf-a1f0-4011-ab42-9fede84550ae",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					allocation: "EA - Hydraplay - Instagram follow",
					...analyticsData,
				}),
			},
		)
			.then((response) => response.json())
			.then((data) => {
				// window.open(
				// 	"https://www.instagram.com/elizabetharden_sa",
				// 	"_blank",
				// );
			})
			.catch((error) => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	};

	const handleFacebookClick = () => {
		const analyticsData = JSON.parse(localStorage.getItem("analyticsData"));
		// Do something with the form data
		window.open(
			"https://web.facebook.com/elizabethardensouthafrica/?_rdc=1&_rdr",
		);

		fetch(
			"https://workflow.tetrice.co.za/webhook/638e93bf-a1f0-4011-ab42-9fede84550ae",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify({
					allocation: "EA - Hydraplay - Facebook like",
					...analyticsData,
				}),
			},
		)
			.then((response) => response.json())
			.then((data) => {})
			.catch((error) => {
				console.error(error);
				this.setState({ isLoading: false });
			});
	};

	const originalWidth = 1294;
	const originalHeight = 965;

	// Calculate the height based on the aspect ratio
	const height = (window.innerWidth * originalHeight) / originalWidth;

	return (
		<View style={styles.container}>
			<View
				style={{
					height: 10,
				}}
			/>

			<View
				style={{
					width: "100%",
					justifyContent: "center",
					alignItems: "center",
					maxWidth: 300,
				}}>
				{/* <Text
					style={{
						fontFamily: "Gotham",
						fontWeight: "600",
						fontSize: 30,
						marginVertical: 5,
						textAlign: "center",
					}}>
					Socials
				</Text> */}
				<Text
					style={{
						fontFamily: "Gotham",
						fontWeight: "400",
						fontSize: 16,
						marginVertical: 10,
						textAlign: "center",
					}}>
					Like, Follow & share on all our social pages
				</Text>

				<TouchableOpacity
					// style={styles.input}
					onPress={() => handleInstagramClick()}>
					<Image
						source={require("../assets/instagram.png")}
						style={{
							width: window.innerWidth * 0.8,
							height: 50,
							resizeMode: "contain",
							marginVertical: 15,
						}}
					/>
				</TouchableOpacity>

				<TouchableOpacity
					// style={styles.input}
					onPress={() => handleFacebookClick()}>
					<Image
						source={require("../assets/facebook.png")}
						style={{
							width: window.innerWidth * 0.8,
							height: 50,
							resizeMode: "contain",
							marginVertical: 15,
						}}
					/>
				</TouchableOpacity>
				{/* <Text
					style={{
						opacity: 0.7,
						fontSize: 16,
					}}>
					or
				</Text> */}
				{/* <Text
					style={{
						// opacity: 0.7,
						marginTop: 18,
						fontFamily: "Gotham",
						fontWeight: "600",
						fontSize: 16,
						textAlign: "center",
					}}>
					Sign up to Join our community
				</Text>
				<View
					style={{
						height: 10,
					}}
				/>

				<TouchableOpacity
					style={{
						// width: "100%",
						// maxWidth: 230,
						// height: 40,
						// borderRadius: 12,
						// backgroundColor: "#E584A7",
						// alignItems: "center",
						// justifyContent: "center",
						marginTop: 16,
						// marginBottom: 80,
					}}
					onPress={() => {
						window.location.href = `${root}/signup`;
					}}>
					<Fade>
						<img
							src={require("../assets/sign-up.png")}
							alt='Description'
							className='responsive'
						/>
					</Fade>
				</TouchableOpacity> */}
			</View>
		</View>
	);
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		// backgroundColor: "#FFF0E8",
		paddingHorizontal: 16,
	},
	title: {
		fontSize: 20,
		fontWeight: "300",

		// marginBottom: 16,
		// marginTop: 25,
		color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: "auto",
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
	},
	row: {
		width: "100%",
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		marginLeft: 8,
		fontSize: 16,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#E3A081",
	},

	label: {
		fontSize: 16,
	},
	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#E3A081",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default FollowUs;
