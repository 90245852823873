import React, { Component, useState, useEffect } from "react";
import { View, TouchableOpacity, Image, Text } from "react-native";
import OverlayComponent from "./OverlayArComp";
import Signups from "../Signup/EaSignups";

export default class DevAr extends Component {
	constructor(props) {
		super(props);
		this.state = {
			videoStream: null,
			showSignup: false,
		};
	}

	async componentDidMount() {
		try {
			const stream = await navigator.mediaDevices.getUserMedia({
				video: true,
				audio: false,
			});
			this.setState({ videoStream: stream });
		} catch (error) {
			console.error("Error accessing camera:", error);
		}
	}

	render() {
		return (
			<View
				style={{
					width: window.innerWidth,
					height: window.innerHeight,
				}}>
				{this.state.showSignup ? (
					<div className='question-section'>
						<Signups root={this.props.root} />
					</div>
				) : (
					<View
						style={{
							width: window.innerWidth,
							height: window.innerHeight,
						}}>
						<View
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 2,
								width: window.innerWidth,
								height: window.innerHeight * 0.8,
								// backgroundColor: "red",
								// opacity: 0,
							}}>
							<iframe
								src='https://mywebar.com/p/8HourHydraPlay'
								frameborder='0'
								seamless
								style={{
									display: "block",
									top: 0,
									width: "100%",
									height: window.innerHeight * 0.8,
								}}
								allow='camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;'></iframe>
						</View>

						{/* <OverlayComponent /> */}

						<View
							style={{
								position: "absolute",
								zIndex: 11,
								bottom: 0,
								width: "100%",
								// borderRadius: 15,
								// padding: 5,
								justifyContent: "center",
								alignItems: "center",
								overflow: "hidden",
							}}>
							{/* <View
								style={{
									overflow: "hidden",
									width: "100%",
									pointerEvents: "none", // Add this line
								}}>
								<img
									src={require("../cloudBottom.webp")}
									alt='Description'
									className='responsive'
								/>
							</View> */}
							<View
								style={{
									width: "100%",
									backgroundColor: "white",
									justifyContent: "center",
									alignItems: "center",
									height: window.innerHeight * 0.2,
									padding: 20,
								}}>
								<Text
									style={{
										fontFamily: "Gotham",
										fontWeight: "700",
										fontSize: 12,
										color: "#000",
										textAlign: "center",
									}}>
									Question:
								</Text>
								<Text
									style={{
										fontFamily: "Gotham",
										fontWeight: "400",
										fontSize: 19,
										color: "#000",
										textAlign: "center",
									}}>
									How many benefits can you count
								</Text>
								<View
									style={{
										flex: 1,
										justifyContent: "space-between",
										alignItems: "center",
										flexDirection: "row",
									}}>
									{[1, 5, 8].map((d) => (
										<TouchableOpacity
											onPress={() => {
												// window.location.href =
												// 	"/signup";

												this.setState({
													showSignup: true,
												});
											}}>
											<View
												style={{
													// width: 40,
													width: 50,
													padding: 10,
													justifyContent: "center",
													alignItems: "center",
													backgroundColor: "#3C97C9",
													borderRadius: 10,
													marginRight: 10,
												}}>
												<Text
													style={{
														fontFamily: "Gotham",
														fontWeight: "400",
														fontSize: 19,
														color: "#FFF",
														textAlign: "center",
													}}>
													{d}
												</Text>
											</View>
										</TouchableOpacity>
									))}
								</View>
							</View>
						</View>

						<View
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 999,

								flexDirection: "row",
								alignItems: "center",
							}}>
							<TouchableOpacity
								onPress={() => {
									window.location.href = "/";
								}}>
								<View
									style={{
										width: 36,
										height: 36,
										justifyContent: "center",
										alignItems: "center",
										borderRadius: 12,
										marginTop: 10,
										marginLeft: 10,
										backgroundColor: "#E8E8E8",
									}}>
									<Image
										source={require("./back.png")}
										style={{
											width: 20,
											height: 20,
											resizeMode: "contain",
										}}
									/>
								</View>
							</TouchableOpacity>
							<Text
								style={{
									fontFamily: "Gotham",
									fontWeight: "700",
									fontSize: 14,
									color: "#FFF",
									// textAlign: "center",
									marginTop: 10,
									marginLeft: 15,
								}}>
								Tap Launch and point your camera at the{" "}
								<strong>Eight Hour® HydraPlay</strong> Bottle
							</Text>
						</View>
					</View>
				)}

				{/* <TouchableOpacity
					style={{
						width: "100%",
					}}
					onPress={() => {
						// window.location.href = `${this.props.root}/quiz`;
						window.location.href = `${this.props.root}/ar`;
					}}>
					<View
						style={{
							// height: 48,
							padding: 10,
							width: "100%",
							background: "#4caf50",
							borderRadius: 10,
							alignItems: "center",
							justifyContent: "center",
							// maxWidth: 380,
						}}>
						<Text
							style={{
								fontFamily: "Avenir",
								fontWeight: "800",
								color: "#FFF",
								fontSize: 19,
							}}>
							Answer Question
						</Text>
					</View>
				</TouchableOpacity> */}
			</View>
		);
	}
}
