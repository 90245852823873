import React, { useEffect, useRef, useState } from "react";
import { View, StyleSheet } from "react-native";

const OverlayComponent = () => {
	const [opacity, setOpacity] = useState(0.1);
	const iframeRef = useRef(null);

	useEffect(() => {
		// Function to handle iframe touch/click
		const handleIframeClick = () => {
			// After the iframe is clicked, wait 5 seconds before changing the opacity
			// setTimeout(() => {
			setOpacity(1);
			// }, 5000);
		};

		// Add event listener to iframe
		const iframe = iframeRef.current;
		if (iframe) {
			// iframe.contentWindow.addEventListener("click", handleIframeClick);
			// iframe.contentWindow.addEventListener(
			// 	"touchstart",
			// 	handleIframeClick,
			// );

			window.addEventListener("touchstart", handleIframeClick);
		}

		// Cleanup listener when component unmounts
		return () => {
			if (iframe) {
				// iframe.contentWindow.removeEventListener(
				// 	"click",
				// 	handleIframeClick,
				// );
				// iframe.contentWindow.removeEventListener(
				// 	"touchstart",
				// 	handleIframeClick,
				// );
				// alert("press");
				window.addEventListener("touchstart", handleIframeClick);

				window.addEventListener("click", handleIframeClick);
			}
		};
	}, []);

	return (
		<View
			style={{
				position: "absolute",
				top: 0,
				left: 0,
				zIndex: 999,
				width: window.innerWidth,
				height: window.innerHeight,
				backgroundColor: "red",
				// opacity: opacity,
				transition: "opacity 0.5s ease",
			}}>
			<iframe
				ref={iframeRef}
				src='https://mywebar.com/p/8HourHydraPlay'
				frameBorder='0'
				seamless
				style={{
					display: "block",
					top: 0,
					left: 0,
					width: window.innerWidth,
					height: window.innerHeight,
					opacity: opacity,
				}}
				allow='camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;microphone;'></iframe>
		</View>
	);
};

const styles = StyleSheet.create({});

export default OverlayComponent;
