import React, { Component } from "react";
import { View, Text } from "react-native";

export default class AppTermsAndConditions extends Component {
	constructor(props) {
		super(props);
		this.state = {
			info: [
				{
					weight: "500",
					data: `1. IMPORTANT NOTICE`,
				},
				{
					weight: "300",
					data: `1.1 These terms and conditions and rules of the Competition contain certain terms and conditions which appear in similar text style to this clause and which:`,
				},
				{
					weight: "300",
					data: `1.1.1 Limit or may limit the risk or liability of the Promoter and/or a third party;`,
				},
				{
					weight: "300",
					data: `1.1.2 Create or may create risk or liability for the Participant;`,
				},
				{
					weight: "300",
					data: `1.1.3 Indemnify or may compel the Participant to indemnify the Promoter and/or a third party;`,
				},
				{
					weight: "300",
					data: `1.1.4 Serves as an acknowledgement, by the Participant, that he/she has read and understood the Terms and Conditions of the Competition.`,
				},
				{
					weight: "300",
					data: `1.2 The Participant's attention is drawn to these Terms and Conditions, which are legally binding and should be carefully considered and noted by the Participant.`,
				},
				{
					weight: "300",
					data: `1.3 Nothing in these Terms and Conditions is intended to or shall be misconstrued as unlawfully restricting and/or limiting any rights and/or obligations created for either the Participant or the Promoter under the Consumer Protection Act, 68 of 2008 ("the CPA").`,
				},
				{
					weight: "300",
					data: `1.4 The Participant acknowledges that by submitting his/her/their entry to the Competition, he/she/they have been given an appropriate opportunity to first read these terms and conditions before entering and agrees to be bound by them.`,
				},
				{
					weight: "300",
					data: `1.5 Should any provision of the Terms and Conditions require interpretation, it will be interpreted by the Promoter in its sole and absolute discretion.`,
				},
				{
					weight: "300",
					data: `1.6 All Participants to this Competition participate entirely at their own risk. The Participant consents to these risks and indemnifies the Promoter and all its subsidiaries and associated entities from any liability pertaining to damage, cost, injuries, and losses sustained as a result of their participation in the Competition or from accepting or using the prize(s) on offer. This includes but is not limited to liability arising out of personal injury, death, property damage, and claims based on publicity rights, defamation, and/or privacy rights.`,
				},
				{
					weight: "500",
					data: `2. RULES OF THE COMPETITION`,
				},
				{
					weight: "300",
					data: `2.1 Prizes are not transferable and may not be exchanged for cash or other prizes.`,
				},
				{
					weight: "300",
					data: `2.2 In order to qualify as a Participant for this Competition:`,
				},
				{
					weight: "300",
					data: `2.2.1 The Participant must live in the Republic of South Africa;`,
				},
				{
					weight: "300",
					data: `2.2.2 The Participant must provide correct and full personal details, as required;`,
				},
				{
					weight: "300",
					data: `2.2.3 The Participant must provide proof of their identity for the Promoter to carry out necessary verification;`,
				},
				{
					weight: "300",
					data: `2.2.4 The Participant cannot be a juristic entity and must be an individual;`,
				},
				{
					weight: "300",
					data: `2.2.5 The Competition is open to Participants of all ages, save that, Participants under the age of 18 years require the prior consent and assistance of their legal guardian to enter. Such Participants warrant the existence of such consent and assistance.`,
				},
				{
					weight: "500",
					data: `3. DURATION OF THE COMPETITION`,
				},
				{
					weight: "300",
					data: `3.1 The Competition will run from 12h00 on 19 September 2023 to 23:59 on Friday 29 September 2023.`,
				},
				{
					weight: "500",
					data: `4. HOW TO ENTER`,
				},
				{
					weight: "300",
					data: `To enter the Competition, the Participant must follow @elizabetharden_sa on Instagram; the Participant must purchase any Eight hour product from Edgars within the month of September 2023; the Participant must submit a photo or screenshot of the receipt/proof of purchase via https://8Hour.skintools.co.za/instabuynow portal, provide their full name and valid form of contact with the receipt. `,
				},
				{
					weight: "500",
					data: `5. PRIZES`,
				},
				{
					weight: "300",
					data: `5.1 One (1) lucky winner will win the following prizes:`,
				},
				{
					weight: "300",
					data: `5.1.1 A Hot Toys Harley Quinn 1/16th size figure.`,
				},

				{
					weight: "300",
					data: `5.2 The prize excludes the cost of transportation to the venue.`,
				},
				{
					weight: "500",
					data: `6. SELECTION OF COMPETITION WINNER/S`,
				},
				{
					weight: "300",
					data: `6.1 Winners will be randomly selected by a panel of judges from all entries. The decision by the judging panel is final and no further deliberation will be entered into.`,
				},
				{
					weight: "300",
					data: `6.2 An announcement of the winners will only take place once the winners have been contacted and all their details verified in accordance with clause 2.2.`,
				},
				{
					weight: "300",
					data: `6.3 Winners will be contacted via email or telephonically by the Promoter. The Promoter reserves the right to disqualify a winner if they do not respond within 2 hours and to select a replacement winner.`,
				},
				{
					weight: "300",
					data: `6.4 Any winner who fails to respond and as a result a replacement winner is selected shall not have any recourse against the Promoter.`,
				},
				{
					weight: "300",
					data: `6.5 Any person who is at the date of awarding the prize/s a director, member, partner, employee, agent or consultant of the Promoter, or any other person who directly or indirectly controls or is controlled by the Promoter, is not eligible to participate in the Competition.`,
				},
				{
					weight: "500",
					data: `7. GENERAL`,
				},
				{
					weight: "300",
					data: `7.1 By entering the Competition in accordance with its terms, the Participant acknowledges and understands that he/she is entering a competition, for the purposes of the CPA and the Competition will be conducted in accordance with the relevant provisions of the CPA. Further, should the Participant win a prize in the Competition, he/she undertakes to expeditiously do all things necessary to enable the Promoter to comply with its obligations under the CPA including, but not limited to:`,
				},
				{
					weight: "300",
					data: `7.1.1 providing such personal information as may be required in order to facilitate handing over the prize (including providing proof of address and identity number); and`,
				},
				{
					weight: "300",
					data: `7.1.2 signing receipt of the prize upon its delivery.`,
				},
				{
					weight: "300",
					data: `7.2 Personal information, as above, shall have the meaning given in the Protection of Personal Information Act, 4 of 2013 ("POPIA”) and any other Regulations, Directives, Decisions or Guidelines on data protection or data privacy and guidance issued by the Information Regulator of South Africa ("Data Protection Legislation").`,
				},
				{
					weight: "300",
					data: `7.3 The Promoter and the Participant shall comply with the requirements applicable to it under the Data Protection Legislation and the data protection principles specified in the Data Protection Legislation that are applicable to it.`,
				},
				{
					weight: "300",
					data: `7.4 The information requested shall not be used for any other purposes outside of the following:`,
				},
				{
					weight: "300",
					data: `7.4.1 For verifying the Participant’s identity for security purposes.`,
				},
				{
					weight: "300",
					data: `7.4.2 Will not be used for marketing of any third party services/products.`,
				},
				{
					weight: "300",
					data: `7.4.3 Shall not under any circumstances divulge the Participant’s contact information to any person outside of the judicial winner selection party.`,
				},
				{
					weight: "300",
					data: `7.5 The Competition and these Terms and Conditions and any dispute pursuant thereto shall be subject to and construed in accordance with the laws of the Republic of South Africa. The South African courts shall have exclusive jurisdiction to determine all/any disputes in respect of/flowing from the application of these Terms and Conditions and otherwise in respect of the Competition.`,
				},
				{
					weight: "300",
					data: `7.6 The winner’s name and location will be displayed on the Promoter’s social media pages after a period of 2 (two) working days subsequent to notification of the winner.`,
				},
				{
					weight: "300",
					data: `7.7 The Promoter shall request the winner’s consent in writing to his her image and/or likeness being used and published by the Promoter in connection with the Competition for a period of 12 (twelve) months after he/she they has/have been announced as the winner. The winner may decline the use of his/her image and/or likeness by the Promoter.`,
				},
				{
					weight: "300",
					data: `7.8 The Promoter shall conduct the Competition, and its decision on any matter related to the Competition, including the selection of prize winners is final and no correspondence will be entered into.`,
				},
				{
					weight: "300",
					data: `7.9 By entering the Competition, the Participant agrees and consents to be bound by any terms and conditions that may be imposed by Instagram.`,
				},
			],
		};
	}

	render() {
		let fs = 13;
		return (
			<View>
				<Text
					style={{
						color: "#000",
						fontSize: fs,
						fontWeight: "500",
					}}>
					COMPETITION TERMS AND CONDITIONS OFFER TO PARTICIPATE –
					ELIZABETH ARDEN x EDGARS EIGHT HOUR COMIC-CON PRIZE DRAW
					(“the Competition”)
				</Text>
				<Text
					style={{
						color: "#000",
						marginVertical: 4,
						fontSize: fs,
					}}>
					The promoter of the Competition is Elizabeth Arden South
					Africa (Pty) Ltd, with registered company no.:
					2000/025864/07 (hereinafter referred to as the “Promoter”).
					All persons entering this Competition ("the Participant/s")
					agree that the rules of the Competition as set out in these
					terms and conditions are legally binding on them in all
					respects.
				</Text>
				{this.state.info.map((d, i) => {
					return (
						<Text
							style={{
								color: "#000",
								marginVertical: 2,
								fontSize: fs,
								fontWeight: d.weight,
								marginTop: d.weight === "500" ? 8 : 2,
							}}>
							{d.data}
						</Text>
					);
				})}
			</View>
		);
	}
}
