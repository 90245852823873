import React, { useState, useEffect } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import LeaderBoardSignup from "./LeaderBoardSignup";
import LeaderBoardSignup2 from "./LeaderBoardSignup2";
import Leaderboard from "./Leaderboard";
import AddScoreForm from "./AddScore";

function LeaderboardApp({ mall }) {
	const [user, setUser] = useState(null);
	const [addUser, setAddUser] = useState(false);
	const [leaderboard, setLeaderboard] = useState([]);

	useEffect(() => {
		fetchLeaderboard();
	}, []);

	const fetchLeaderboard = () => {
		// Fetch leaderboard from server
		fetch(
			"https://workflow.tetrice.co.za/webhook/660af5e5-ad2a-49c5-ae58-49f06652e1f5",
		)
			.then((response) => response.json())
			.then((data) => setLeaderboard(data))
			.catch((error) =>
				console.error("Error fetching leaderboard:", error),
			);
	};

	const fetchPlayer = (d) => {
		// Fetch leaderboard from server
		fetch(
			"https://workflow.tetrice.co.za/webhook/8b1505a5-bf86-465a-9e18-bd2d51df016f",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(d),
			},
		)
			.then((response) => response.json())
			.then((data) => setUser(data))
			.catch((error) =>
				console.error("Error fetching leaderboard:", error),
			);
	};

	const handleSignup = (d) => {
		fetchPlayer(d);
	};

	const handleAddScore = (newScore) => {
		// Update leaderboard locally
		setLeaderboard([...leaderboard, newScore]);

		// Optionally, send new score to the server here
		fetch(
			"https://workflow.tetrice.co.za/webhook/6a5a69b6-2faa-4e9f-bd02-552101f77139",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
				},
				body: JSON.stringify(newScore),
			},
		)
			.then((response) => response.json())
			.then((data) => {
				console.log("Score added:", data);
				setUser("");
				setAddUser("");
				fetchLeaderboard();
			})
			.catch((error) => console.error("Error adding score:", error));
	};

	return (
		<View
			style={{
				flex: 1,
				justifyContent: "center",
				alignItems: "center",
			}}>
			<Image
				source={require("../Quiz/Background.jpg")}
				style={{
					// width: window.innerWidth,
					// height: window.innerHeight,
					width: "100%", // or specify a fixed width like width: 500
					height: "100%", // or specify a fixed height like height: 300
					resizeMode: "cover",
					position: "absolute",
					top: 0,
				}}
			/>
			<View
				style={{
					flex: 1,
					width: "100%",
					maxWidth: 400,
					// paddingTop: 10,
					// padding: 2,
				}}>
				<View
					style={{
						flexDirection: "row",
						backgroundColor: "#000",
						width: "100%",
						maxWidth: 500,
						// borderRadius: 15,
						paddingRight: 10,
					}}>
					<View
						style={{
							// flex: 1,
							// flexDirection: "row",
							// alignItems: "center",
							// paddingRight: 5,
							width: "100%",
							padding: 35,
						}}>
						<View
							style={{
								flex: 1,
								// alignItems: "center",
							}}>
							<View
								style={{
									marginBottom: 3,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "700",
										fontSize: 20,
										color: "#FFF",
										opacity: 0.9,
									}}>
									PADEL
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									marginBottom: 3,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "700",
										fontSize: 30,
										color: "#FFF",
									}}>
									LEADERBOARD
								</Text>
							</View>
							<View
								style={{
									flexDirection: "row",
									alignItems: "center",
									opacity: 0.9,
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontWeight: "700",
										fontSize: 20,
										color: "#FFF",
									}}>
									#HydraPlayYourWay
								</Text>
							</View>
						</View>
					</View>
				</View>
				<View
					style={{
						width: "100%",
						padding: 10,
						minHeight: window.innerHeight - 150,
					}}>
					<TouchableOpacity
						style={{}}
						onPress={async () => {
							setAddUser(true);
						}}>
						<View
							style={{
								// height: 35,
								padding: 10,
								justifyContent: "center",
								alignItems: "center",
								backgroundColor: "red",
								borderRadius: 15,
							}}>
							<Text
								style={{
									color: "#FFF",
									fontSize: 15,
									fontFamily: "Gotham",
									fontWeight: "300",
								}}>
								Add player
							</Text>
						</View>
					</TouchableOpacity>
					<Leaderboard leaderboard={leaderboard} />
				</View>
			</View>

			<View
				style={{
					position: "absolute",
					bottom: 0,
				}}>
				{/* <LeaderBoardSignup onSignup={handleSignup} /> */}

				{addUser ? (
					!user ? (
						<LeaderBoardSignup2 onSignup={handleSignup} />
					) : (
						<div>
							{/* <Leaderboard leaderboard={leaderboard} /> */}
							<AddScoreForm
								onAddScore={handleAddScore}
								user={user}
								mall={mall}
							/>
						</div>
					)
				) : null}
			</View>
		</View>
	);
}

export default LeaderboardApp;
