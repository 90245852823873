import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
} from "react-native";
import { api_formsubmit } from "../Api";
import EmailInput from "../EmailInput";
import { PopiaCheckboxes } from "./POPIA";
import Fade from "react-reveal/Fade";
import SouthAfricanCellNumberInput from "../SouthAfricanCellNumberInput";
import moment from "moment";
import { Modal } from "react-native-web";

function uuid() {
	return "xxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toLocaleUpperCase();
	});
}

const SignUp = ({ onSignup, root }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [signupData, setsignupData] = useState({
		firstName: "",
		lastName: "",
		email: "",
		cell: "",
		preferredMethod: "",
	});

	const handleChange = (name, value) => {
		setsignupData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		// e.preventDefault();

		const otp = uuid();
		setIsLoading(true);
		let date = moment().toISOString();

		if (signupData.email || signupData.cell) {
			onSignup({
				otp: otp,
				user_data: signupData,
				date: date,
			});
		}
	};

	const originalWidth = 1294;
	const originalHeight = 965;

	let height = window.innerHeight * 0.5;
	let width = window.innerWidth;

	width = width > 600 ? 600 : width;

	return (
		<Modal>
			<View style={styles.container}>
				{isLoading ? (
					<View style={styles.loadingContainer}>
						<Image
							source={require("../assets/ealogo.png")}
							style={{
								width: 250,
								height: 250,
								resizeMode: "contain",
							}}
						/>
						<ActivityIndicator size='large' color='#11C0E1' />
					</View>
				) : (
					<View style={{ flex: 1 }}>
						<ScrollView showsVerticalScrollIndicator={false}>
							<View
								style={{
									flex: 1,
									alignItems: "center",
									paddingTop: 100,
								}}>
								<Text
									style={{
										width: "100%",
										maxWidth: 350,
										fontSize: 20,
										fontWeight: "600",
										marginBottom: 10,
									}}>
									Hydraplay Padel Signup
								</Text>

								<Text
									style={{
										width: "100%",
										maxWidth: 350,
										fontSize: 20,
										fontWeight: "300",
										marginBottom: 20,
									}}>
									Fill in your contact details to enter your
									name into the game.
								</Text>

								<View
									style={{
										height: 10,
										padding: 10,
										width: "100%",
										maxWidth: 350,
									}}
								/>
								<View
									style={{
										// padding: 10,
										width: "100%",
										maxWidth: 350,
									}}></View>

								<TextInput
									autoCapitalize='words'
									style={styles.input}
									placeholder='First Name'
									value={signupData.firstName}
									onChangeText={(value) =>
										handleChange("firstName", value)
									}
								/>
								<TextInput
									autoCapitalize='words'
									style={styles.input}
									placeholder='Last Name'
									value={signupData.lastName}
									onChangeText={(value) =>
										handleChange("lastName", value)
									}
								/>

								<View style={styles.row}>
									<Text style={styles.label}>
										Preferred method of contact:
									</Text>
									<View style={styles.buttonContainer}>
										<TouchableOpacity
											style={[
												{
													flex: 1,
													// width: "80%",
													height: 48,
													borderRadius: 12,
													backgroundColor: "#B1ACAA",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 16,
												},
												signupData.preferredMethod ===
													"email" &&
													styles.activeButton,
											]}
											onPress={() =>
												handleChange(
													"preferredMethod",
													"email",
												)
											}>
											<Text style={styles.buttonText}>
												Email
											</Text>
										</TouchableOpacity>
										<View
											style={{
												width: 10,
											}}
										/>
										<TouchableOpacity
											style={[
												{
													flex: 1,
													height: 48,
													borderRadius: 12,
													backgroundColor: "#B1ACAA",
													alignItems: "center",
													justifyContent: "center",
													marginTop: 16,
												},
												signupData.preferredMethod ===
													"cell" &&
													styles.activeButton,
											]}
											onPress={() => {
												handleChange(
													"preferredMethod",
													"cell",
												);
												handleChange("cell", "");
												handleChange("email", "");
											}}>
											<Text style={styles.buttonText}>
												Cell
											</Text>
										</TouchableOpacity>
									</View>
								</View>

								{/* {signupData.preferredMethod !== "" ? ( */}
								<View
									style={{
										padding: 10,
										width: "100%",
										maxWidth: 350,
										marginBottom: 200,

										alignItems: "center",
										justifyContent: "center",
										opacity:
											signupData.preferredMethod !== ""
												? 1
												: 0,
									}}>
									<PopiaCheckboxes
										popiaData={(val) =>
											handleChange("popiaData", val)
										}
									/>
									{signupData.preferredMethod === "email" ? (
										<EmailInput
											onChange={(val) =>
												handleChange("email", val)
											}
										/>
									) : (
										<SouthAfricanCellNumberInput
											onChange={(val) =>
												handleChange("cell", val)
											}
										/>
									)}
									{signupData.email !== "" ||
									signupData.cell !== "" ? (
										<TouchableOpacity
											disabled={
												signupData.preferredMethod ===
												"email"
													? signupData.email === ""
													: signupData.cell === ""
											}
											style={{
												alignItems: "center",
												justifyContent: "center",
												marginTop: 16,
												marginBottom: 80,
											}}
											onPress={() => handleSubmit()}>
											<Fade>
												{/* <Image
												source={require("../assets/sign-up.png")}
												style={{
													width:
														window.innerWidth * 0.9,
													height: 250,
													resizeMode: "contain",
												}}
												/> */}
												<img
													src={require("../Button HR.png")}
													alt='Description'
													className='responsive'
												/>
											</Fade>
										</TouchableOpacity>
									) : null}
								</View>
								{/* ) : null} */}
							</View>
						</ScrollView>
					</View>
				)}
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		alignItems: "center",
		// justifyContent: "center",
		backgroundColor: "#f0f8ff",
		width: "100%",
		// paddingHorizontal: 16,
		height: window.innerHeight,
	},
	loadingContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FFF",
		paddingHorizontal: 16,
		minHeight: window.innerHeight,
	},
	title: {
		// fontSize: 20,
		// fontWeight: "300",
		// marginBottom: 16,
		// marginTop: 25,
		// color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: 48,
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
		backgroundColor: "#FFF",
	},
	row: {
		// padding: 10,
		width: "100%",
		maxWidth: 350,
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
		marginTop: 10,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		fontSize: 15,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#D44343",
	},

	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#D44343",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default SignUp;
