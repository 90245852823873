// import React, { Component } from "react";
// import { View, Text, TextInput, StyleSheet } from "react-native";

// class SouthAfricanCellNumberInput extends Component {
// 	state = {
// 		value: "",
// 		error: null,
// 	};

// 	handleChange = (value) => {
// 		// Remove any non-numeric characters from the input value
// 		const newValue = value.replace(/[^0-9]/g, "");

// 		// Validate the new value as a South African cell number
// 		const isValid = /^(\+27|0)[1-8][0-9]{8}$/.test(newValue);

// 		// Update the state with the new value and error (if any)
// 		this.setState({
// 			value: newValue,
// 			error: isValid
// 				? null
// 				: "Please enter a valid South African cell number",
// 		});

// 		// Pass the valid cell phone number to the parent component
// 		if (isValid && this.props.onChange) {
// 			this.props.onChange(newValue);
// 		} else {
// 			this.props.onChange("");
// 		}
// 	};

// 	render() {
// 		const { value, error } = this.state;

// 		return (
// 			<View style={styles.container}>
// 				<TextInput
// 					style={[
// 						styles.input,
// 						error ? styles.inputError : styles.inputValid,
// 					]}
// 					keyboardType='phone-pad'
// 					value={value}
// 					placeholder='Cell number'
// 					onChangeText={this.handleChange}
// 				/>
// 				{error && <Text style={styles.error}>{error}</Text>}
// 			</View>
// 		);
// 	}
// }

// const styles = StyleSheet.create({
// 	container: {
// 		width: "100%",
// 		alignItems: "center",
// 		justifyContent: "center",
// 	},
// 	input: {
// 		width: "100%",
// 		height: "auto",
// 		borderWidth: 1,
// 		paddingHorizontal: 10,
// 		padding: 15,

// 		borderRadius: 12,
// 		borderWidth: 1,
// 		borderColor: "#ccc",
// 		paddingLeft: 16,
// 		marginBottom: 16,
// 		fontSize: 17,

// 		// textAlign: "center",
// 	},
// 	inputValid: {
// 		// borderColor: "green",
// 	},
// 	inputError: {
// 		borderColor: "red",
// 	},
// 	error: {
// 		color: "red",
// 		fontSize: 14,
// 		marginTop: 5,
// 	},
// });

// export default SouthAfricanCellNumberInput;

import React, { Component } from "react";
import { View, Text, TextInput, StyleSheet } from "react-native";

class SouthAfricanCellNumberInput extends Component {
	constructor(props) {
		super(props);

		this.state = {
			value: props.defaultValue || "",
			error: null,
		};
	}

	handleChange = (value) => {
		// Remove any non-numeric characters from the input value
		const newValue = value.replace(/[^0-9]/g, "");

		// Validate the new value as a South African cell number
		const isValid = /^(\+27|0)[1-8][0-9]{8}$/.test(newValue);

		// Update the state with the new value and error (if any)
		this.setState({
			value: newValue,
			error: isValid
				? null
				: "Please enter a valid South African cell number",
		});

		// Pass the valid cell phone number to the parent component
		if (isValid && this.props.onChange) {
			this.props.onChange(newValue);
		} else {
			this.props.onChange("");
		}
	};

	render() {
		const { value, error } = this.state;

		return (
			<View style={styles.container}>
				<TextInput
					style={[
						styles.input,
						error ? styles.inputError : styles.inputValid,
					]}
					keyboardType='phone-pad'
					value={value}
					placeholder='Cell number'
					onChangeText={this.handleChange}
				/>
				{error && <Text style={styles.error}>{error}</Text>}
			</View>
		);
	}
}

const styles = StyleSheet.create({
	container: {
		width: "100%",
		alignItems: "center",
		justifyContent: "center",
	},
	input: {
		width: "100%",
		height: "auto",
		borderWidth: 1,
		paddingHorizontal: 10,
		padding: 15,
		backgroundColor: "#FFF",
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,

		// textAlign: "center",
	},
	inputValid: {
		// borderColor: "green",
	},
	inputError: {
		borderColor: "red",
	},
	error: {
		color: "red",
		fontSize: 14,
		marginTop: 5,
	},
});

export default SouthAfricanCellNumberInput;
