import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
import { api_formsubmit } from "../Api";
import EmailInput from "../EmailInput";
import { PopiaCheckboxes } from "./POPIA";
import Fade from "react-reveal/Fade";
import SouthAfricanCellNumberInput from "../SouthAfricanCellNumberInput";
import moment from "moment";

function uuid() {
	return "xxxxxx".replace(/[xy]/g, function (c) {
		var r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16).toLocaleUpperCase();
	});
}

const SignUp2 = ({ onSignup, root }) => {
	const [isLoading, setIsLoading] = useState(false);
	const [signupData, setsignupData] = useState({
		uniqueCode: "",
		mall: "",
	});

	const handleChange = (name, value) => {
		setsignupData((prevState) => ({
			...prevState,
			[name]: value,
		}));
	};

	const handleSubmit = async () => {
		// e.preventDefault();
		let date = moment().toISOString();
		// const otp = uuid();
		onSignup({
			...signupData,
			date: date,
		});
		// setIsLoading(true);
		// let date = moment().toISOString();

		// if (signupData.email || signupData.cell) {
		// 	onSignup({
		// 		otp: otp,
		// 		user_data: signupData,
		// 		date: date,
		// 	});
		// }
	};

	const originalWidth = 1294;
	const originalHeight = 965;

	let height = window.innerHeight * 0.5;
	let width = window.innerWidth;

	width = width > 600 ? 600 : width;

	return (
		<Modal>
			<View style={styles.container}>
				{isLoading ? (
					<View style={styles.loadingContainer}>
						<Image
							source={require("../assets/ealogo.png")}
							style={{
								width: 250,
								height: 250,
								resizeMode: "contain",
							}}
						/>
						<ActivityIndicator size='large' color='#11C0E1' />
					</View>
				) : (
					<View style={{ flex: 1 }}>
						<ScrollView showsVerticalScrollIndicator={false}>
							<View
								style={{
									flex: 1,

									justifyContent: "center",
									paddingTop: 100,
								}}>
								<Text
									style={{
										width: "100%",
										textAlign: "center",
										maxWidth: 350,
										fontSize: 25,
										fontWeight: "600",
										marginBottom: 10,
									}}>
									Fill in your unique code
								</Text>

								{/* <Text
									style={{
										width: "100%",
										maxWidth: 350,
										textAlign: "center",
										fontSize: 20,
										fontWeight: "300",
										marginBottom: 20,
									}}>
									Fill in your unique code
								</Text> */}

								<View
									style={{
										height: 10,
										padding: 10,
										width: "100%",
										maxWidth: 350,
									}}
								/>
								<View
									style={{
										// padding: 10,
										width: "100%",
										maxWidth: 350,
									}}></View>

								<TextInput
									autoCapitalize='characters'
									style={{
										width: "100%",
										height: 48,
										borderRadius: 12,
										borderWidth: 1,
										borderColor: "#ccc",
										paddingLeft: 16,
										marginBottom: 16,
										fontSize: 18,
										backgroundColor: "#FFF",
										textAlign: "center",
										fontWeight: "600",
									}}
									keyboardType='decimal-pad'
									placeholder='Code'
									value={signupData.uniqueCode}
									onChangeText={(value) =>
										handleChange("uniqueCode", value)
									}
								/>
								{/* <TextInput
									autoCapitalize='words'
									style={styles.input}
									placeholder='Last Name'
									value={signupData.lastName}
									onChangeText={(value) =>
										handleChange("lastName", value)
									}
								/> */}

								{signupData.uniqueCode !== "" ? (
									<TouchableOpacity
										// disabled={
										// 	signupData.mall === "email"
										// 		? signupData.email === ""
										// 		: signupData.cell === ""
										// }
										style={{
											alignItems: "center",
											justifyContent: "center",
											marginTop: 16,
											marginBottom: 80,
											width: "100%",
											maxWidth: 300,
										}}
										onPress={() => handleSubmit()}>
										<Fade>
											<img
												src={require("../Button HR.png")}
												alt='Description'
												className='responsive'
											/>
										</Fade>
									</TouchableOpacity>
								) : null}
							</View>
						</ScrollView>
					</View>
				)}
			</View>
		</Modal>
	);
};

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		alignItems: "center",
		// justifyContent: "center",
		backgroundColor: "#f0f8ff",
		width: "100%",
		// paddingHorizontal: 16,
		height: window.innerHeight,
	},
	loadingContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FFF",
		paddingHorizontal: 16,
		minHeight: window.innerHeight,
	},
	title: {
		// fontSize: 20,
		// fontWeight: "300",
		// marginBottom: 16,
		// marginTop: 25,
		// color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: 48,
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
		backgroundColor: "#FFF",
	},
	row: {
		// padding: 10,
		width: "100%",
		maxWidth: 350,
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
		marginTop: 10,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		fontSize: 15,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#D44343",
	},

	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#D44343",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default SignUp2;
