import React, { Component } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
} from "react-native";
import "../index.css";
import "./InputStyle.css";

class ReceiptUploader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			file: null,
			fileName: "",
			fileError: "",
			resizedImage: "",
		};
	}

	handleChange = async (event) => {
		const file = event.target.files[0];
		const fileName = file.name;

		// Check file type (optional)
		if (!file.type.match("image.*")) {
			this.setState({ fileError: "Please upload an image file" });
			return;
		}

		await this.setState({
			file: file,
			fileName: fileName,
			fileError: "",
		});

		await this.handleSubmit();
	};

	handleSubmit = async () => {
		// Check if a file has been selected
		if (!this.state.file) {
			this.setState({ fileError: "Please select a file to upload" });
			return;
		}

		// Convert file to base64 string
		const reader = new FileReader();
		reader.readAsDataURL(this.state.file);
		reader.onload = async () => {
			const base64String = reader.result;

			// Resize image to lower quality
			const desiredWidth = 700; // Set desired width in pixels
			const quality = 0.6; // Set desired quality (between 0 and 1)
			const resizedBase64 = await this.resizeImage(
				base64String,
				desiredWidth,
				quality,
			);

			this.setState({ resizedImage: resizedBase64 });

			// Call function to handle uploading the file
			this.props.handleUpload(resizedBase64);
		};
	};

	resizeImage = (base64String, desiredWidth, quality) => {
		return new Promise((resolve, reject) => {
			const img = new window.Image();
			img.src = base64String;
			img.onload = () => {
				const canvas = document.createElement("canvas");
				const ctx = canvas.getContext("2d");
				const scaleFactor = desiredWidth / img.width;
				canvas.width = desiredWidth;
				canvas.height = img.height * scaleFactor;
				ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

				// Get file type from base64 string
				const fileType = base64String.match(
					/data:image\/([a-zA-Z]*);base64/,
				)[1];

				const resizedBase64 = canvas.toDataURL(
					`image/${fileType}`,
					quality,
				);
				resolve(resizedBase64);
			};
			img.onerror = (error) => {
				reject(error);
			};
		});
	};

	render() {
		return (
			<View
				style={{
					width: window.innerWidth * 0.9,
					width: "100%",
					height: this.state.resizedImage !== "" ? 250 : "auto",
					minHeight: 200,
					borderRadius: 12,
					borderWidth: this.state.resizedImage !== "" ? 1 : 0,
					borderColor: "#ccc",
					paddingLeft: 16,
					marginBottom: 16,
					fontSize: 17,
					justifyContent: "center",
					alignItems: "center",
					backgroundColor: "#FFF",
					maxHeight: 500,
				}}>
				{this.state.resizedImage !== "" ? (
					<Image
						style={{
							width: 100,
							height: 200,
							position: "absolute",
							resizeMode: "contain",
						}}
						source={{
							uri: this.state.resizedImage,
						}}
					/>
				) : (
					<View>
						{/* <Text
							style={{
								marginBottom: 20,
								fontSize: 17,
								fontWeight: "600",
							}}>
							+ Upload a photo / screenshot of your Edgars Receipt
						</Text> */}
						{/* <input
							type='file'
							class='file-upload-input'
							onChange={this.handleChange}
						/> */}

						<div class='file-upload-wrapper'>
							<img
								src={require("../assets/RECEIPT.png")}
								alt='Upload Image'
								className='responsive'
							/>
							<input
								type='file'
								class='file-upload-input'
								onChange={this.handleChange}
							/>
						</div>

						<canvas
							style={{
								display: "none",
							}}></canvas>
					</View>
				)}
			</View>
		);
	}
}

export default ReceiptUploader;
