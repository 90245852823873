import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";

function AddScoreForm({ onAddScore, user, mall }) {
	const [score, setScore] = useState("");
	// const [mall, setsmall] = useState("");

	const handleSubmit = () => {
		const newScore = {
			...user,
			score: parseInt(score),
			mall: mall,
		};

		// console.log(newScore);
		// return;
		onAddScore(newScore);
		setScore(""); // Reset score input after submission
		//
	};

	return (
		<Modal>
			<View
				style={{
					// flex: 1,
					backgroundColor: "#FFF",
					borderRadius: 15,
					width: "100%",
					// maxWidth: 500,
					padding: 10,
					marginBottom: 20,
					alignItems: "center",
					// justifyContent: "center",
				}}>
				<View
					style={{
						width: 200,
						height: 90,
						marginBottom: 20,
					}}>
					<Image
						source={{
							uri: "https://www.elizabetharden.co.za/images/sitewide/EA-Logo-2020-320x59.svg",
						}}
						style={{
							width: 200,
							height: 90,
							resizeMode: "contain",
						}}
					/>
				</View>
				<View
					style={{
						width: "100%",
						maxWidth: 500,
						alignItems: "center",
						justifyContent: "center",
					}}>
					{/* <Text
							style={{
								color: "#000",
								fontSize: 25,
								fontWeight: "600",
							}}>
							Enter Score
						</Text> */}
					<Text
						style={{
							color: "#000",
							fontSize: 25,
						}}>
						{user.user_data.firstName} {user.user_data.lastName}
					</Text>
					{/* <input
						type='number'
						value={score}
						onChange={(e) => setScore(e.target.value)}
						placeholder='Enter your score'
						required
					/> */}
					<TextInput
						autoFocus
						keyboardType='decimal-pad'
						onChange={(e) => setScore(e.target.value)}
						placeholder='Padel Score'
						maxLength={4}
						style={{
							padding: 10,
							// borderWidth: 1,
							// borderRadius: 10,
							width: "100%",
							fontSize: 25,
							textAlign: "center",
							color: "blue",
							borderBottomWidth: 1,
							marginVertical: 20,
						}}
					/>

					{/* <View style={styles.row}>
						<Text style={styles.label}>Select Mall</Text>
						<View style={styles.buttonContainer}>
							<TouchableOpacity
								style={[
									{
										flex: 1,
										// width: "80%",
										height: 48,
										borderRadius: 12,
										backgroundColor: "#B1ACAA",
										alignItems: "center",
										justifyContent: "center",
										marginTop: 16,
									},
									mall === "Centurion" && styles.activeButton,
								]}
								onPress={() => setsmall("Centurion")}>
								<Text style={styles.buttonText}>Centurion</Text>
							</TouchableOpacity>
							<View
								style={{
									width: 10,
								}}
							/>
							<TouchableOpacity
								style={[
									{
										flex: 1,
										height: 48,
										borderRadius: 12,
										backgroundColor: "#B1ACAA",
										alignItems: "center",
										justifyContent: "center",
										marginTop: 16,
									},
									mall === "Sandton" && styles.activeButton,
								]}
								onPress={() => {
									setsmall("Sandton");
									// handleChange("cell", "");
									// handleChange("email", "");
								}}>
								<Text style={styles.buttonText}>Sandton</Text>
							</TouchableOpacity>
							<View
								style={{
									width: 10,
								}}
							/>
							<TouchableOpacity
								style={[
									{
										flex: 1,
										// width: "80%",
										height: 48,
										borderRadius: 12,
										backgroundColor: "#B1ACAA",
										alignItems: "center",
										justifyContent: "center",
										marginTop: 16,
									},
									mall === "East Gate" && styles.activeButton,
								]}
								onPress={() => setsmall("East Gate")}>
								<Text style={styles.buttonText}>East Gate</Text>
							</TouchableOpacity>
						</View>
					</View> */}

					{mall !== "" ? (
						<TouchableOpacity
							// disabled={
							// 	signupData.mall === "email"
							// 		? signupData.email === ""
							// 		: signupData.cell === ""
							// }
							style={{
								alignItems: "center",
								justifyContent: "center",
								marginTop: 16,
								marginBottom: 80,
								width: "100%",
								maxWidth: 350,
							}}
							onPress={() => handleSubmit()}>
							<img
								src={require("../Button HR.png")}
								alt='Description'
								className='responsive'
							/>
						</TouchableOpacity>
					) : null}
				</View>
			</View>
		</Modal>
	);
}

const styles = StyleSheet.create({
	container: {
		// flex: 1,
		alignItems: "center",
		// justifyContent: "center",
		backgroundColor: "#f0f8ff",
		width: "100%",
		// paddingHorizontal: 16,
		height: window.innerHeight,
	},
	loadingContainer: {
		flex: 1,
		alignItems: "center",
		justifyContent: "center",
		backgroundColor: "#FFF",
		paddingHorizontal: 16,
		minHeight: window.innerHeight,
	},
	title: {
		// fontSize: 20,
		// fontWeight: "300",
		// marginBottom: 16,
		// marginTop: 25,
		// color: "#6d6e70",
		// fontFamily: "Bodoni Moda",
		// fontFamily: "Lora",
	},
	input: {
		width: "100%",
		height: 48,
		borderRadius: 12,
		borderWidth: 1,
		borderColor: "#ccc",
		paddingLeft: 16,
		marginBottom: 16,
		fontSize: 17,
		backgroundColor: "#FFF",
	},
	row: {
		// padding: 10,
		width: "100%",
		maxWidth: 350,
		// flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",

		marginBottom: 20,
		marginTop: 10,
	},

	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginBottom: 16,
	},
	label: {
		fontSize: 15,
	},
	button: {},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
	signInContainer: {
		flexDirection: "row",
		marginTop: 16,
	},
	signInText: {
		fontSize: 16,
		marginRight: 8,
	},
	signInLink: {
		fontSize: 16,
		color: "#D44343",
	},

	buttonContainer: {
		width: "100%",
		flexDirection: "row",
		alignItems: "center",
		justifyContent: "space-between",
		marginTop: 8,
	},
	button: {
		// width: "48%",
		flex: 1,
		height: 48,
		borderRadius: 12,
		backgroundColor: "#ccc",
		alignItems: "center",
		justifyContent: "center",
	},
	activeButton: {
		backgroundColor: "#D44343",
	},
	buttonText: {
		color: "#fff",
		fontSize: 16,
	},
});

export default AddScoreForm;
