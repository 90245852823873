import React, { useState } from "react";
import {
	View,
	Text,
	TextInput,
	StyleSheet,
	TouchableOpacity,
	Image,
	ActivityIndicator,
	ScrollView,
	Modal,
} from "react-native";
import AppTermsAndConditions from "./AppTermsAndConditions";

export const TermsAndConditionsCheckBox = ({ isAccepted, isCheckBoxType }) => {
	const [formData, setFormData] = useState({
		tandcs: false,
		accept: false,
	});
	const [applyNow, setapplyNow] = useState(false);
	const [accept, setAccept] = useState(false);
	const handleChange = (field) => {
		setFormData((prevState) => ({
			...prevState,
			[field]: !prevState[field],
		}));
	};

	const tandcModal = () => {
		let screenHeight = window.innerHeight;
		let screenWidth = window.innerWidth;

		screenWidth = screenWidth >= 500 ? 500 : screenWidth;
		return (
			<Modal
				animationType='fade'
				transparent={true}
				visible={applyNow}
				onRequestClose={() => {
					setapplyNow(false);
				}}>
				<View
					style={{
						flex: 1,
						position: "absolute",
						zIndex: 999,
						backgroundColor: "rgba(52, 52, 52, 0.8)",
						width: window.innerWidth,
						height: window.innerHeight,
					}}>
					<View
						style={{
							flex: 1,
							justifyContent: "center",
							alignItems: "center",
							padding: 10,
						}}>
						<View
							style={{
								width: "100%",
								height: screenHeight - 120,
								backgroundColor: "#FFF",
								borderRadius: 20,
								// padding: 10,
								justifyContent: "center",
								alignItems: "center",
								shadowColor: "#999797",
								shadowOffset: {
									width: 0,
									height: 4,
								},
								shadowOpacity: 0.3,
								shadowRadius: 4.65,
								elevation: 8,
							}}>
							<View
								style={{
									width: "100%",
									padding: 20,
									height: "auto",
								}}>
								<Text
									allowFontScaling={false}
									style={{
										fontSize: 19,
										fontFamily: "Avenir Next",
										textAlign: "center",
										fontWeight: "700",
									}}>
									Terms and conditions
								</Text>
							</View>
							<View
								style={{
									flex: 1,
									width: "100%",
								}}>
								<ScrollView>
									<View
										style={{
											padding: 10,
										}}>
										<AppTermsAndConditions />
									</View>
								</ScrollView>
							</View>
							<View
								style={{
									width: "100%",
									padding: 10,
								}}>
								<TouchableOpacity
									onPress={async () => {
										setAccept(true);
										setapplyNow(false);

										isAccepted(true);
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderRadius: 10,
											backgroundColor: "#000",
											justifyContent: "center",
											alignItems: "center",
										}}>
										<Text
											style={{
												color: "#FFF",
												fontWeight: "300",
												fontSize: 16,
											}}>
											{isCheckBoxType ? "Accept" : "Done"}
										</Text>
									</View>
								</TouchableOpacity>

								<TouchableOpacity
									onPress={() => {
										setapplyNow(false);
									}}>
									<View
										style={{
											width: "100%",
											padding: 10,
											borderRadius: 10,
											// backgroundColor: '#000',
											justifyContent: "center",
											alignItems: "center",
											display: isCheckBoxType
												? "flex"
												: "none",
										}}>
										<Text
											style={{
												color: "#000",
												fontWeight: "300",
												fontSize: 16,
											}}>
											cancel
										</Text>
									</View>
								</TouchableOpacity>
							</View>
						</View>
					</View>
				</View>
			</Modal>
		);
	};

	if (isCheckBoxType) {
		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						setapplyNow(true);
						// handleChange("tandcs");
					}}>
					<View style={styles.checkboxContainer}>
						<View
							style={{
								width: 19,
								height: 19,
								borderRadius: 5,
								backgroundColor: accept
									? "#11C0E1"
									: "transparent",
								borderWidth: 1,
								borderColor: accept ? "#11C0E1" : "#000",
							}}
						/>
						<Text style={styles.label}>
							I have read and accept competition terms and
							conditions
						</Text>
					</View>
				</TouchableOpacity>
				{applyNow ? tandcModal() : null}
			</View>
		);
	} else {
		return (
			<View style={styles.container}>
				<TouchableOpacity
					onPress={() => {
						setapplyNow(true);
						// handleChange("tandcs");
					}}>
					<View style={styles.checkboxContainer}>
						<Text
							style={{
								fontFamily: "Gotham",
								fontWeight: "400",
								fontSize: 13,
								marginTop: 15,
								// color: "#485F99",
							}}>
							Terms & conditions apply
						</Text>
					</View>
				</TouchableOpacity>
				{applyNow ? tandcModal() : null}
			</View>
		);
	}
};

const styles = StyleSheet.create({
	container: {
		flex: 1,
		// alignItems: "center",
		// justifyContent: "center",
		// marginBottom: 20,
		// marginTop: 20,
	},
	title: {
		fontSize: 20,
		fontWeight: "bold",
		marginBottom: 20,
	},
	checkboxContainer: {
		flexDirection: "row",
		alignItems: "center",
		marginVertical: 10,
	},
	label: {
		marginLeft: 8,
		fontSize: 16,
	},
	button: {
		backgroundColor: "blue",
		paddingVertical: 10,
		paddingHorizontal: 20,
		borderRadius: 5,
		marginTop: 20,
	},
	buttonText: {
		color: "white",
		fontWeight: "bold",
		fontSize: 16,
	},
});
